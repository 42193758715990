import React from 'react';
import Layouts from 'layouts';
import OgImage from 'images/promotions/banner-alltopasia-promotion-cover--6.5%-per-week.jpg';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import ComponentTemplate from '../../promotion-template/component-template';

const PromotionsPage = (props) => {
  const intl = useIntl();
  const { location } = props;

  return (
    <Layouts
      title={intl.formatMessage({ id: 'page.home.title' })}
      description={intl.formatMessage({ id: 'page.home.description' })}
      image={OgImage}
      pathname={location.pathname}
    >
      <ComponentTemplate
        cover="/2022-9-9-sport-500.jpg"
        title="สมาชิกใหม่ กีฬา ฝาก 300 ถึง 499 รับโบนัส 30%"
        subTitle="ยอดฝาก 300 ถึง 499 รับโบนัส 30%"
        //
        desc="ยอดฝาก 300 ถึง 499 รับโบนัส 30% ทำยอดชนะ 5 เท่า ถอนได้สูงสุด 100 เท่าของยอดฝาก"
        howto="ยอดฝาก = 400 บาท ยอดโบนัส = 120 บาท ยอดชนะที่ต้องการ = (400 + 20 ) x 9 = 4,680 บาท (ไม่รวมทุน)"
        note="โปรโมชั่นนี้สำหรับการเดิมพันประเภท sport เท่านั้น หากสมาชิกมีการเดิมพันประเภท Sport,Casino ทาง alltopasia จะทำการคืนทุนทุกกรณี"
      />
    </Layouts>
  );
};

PromotionsPage.propTypes = {
  location: PropTypes.string.isRequired,
};

export default PromotionsPage;
